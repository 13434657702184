import { addQueryParam } from '@canalplus/mycanal-commons';
import { PAGINATION_CONTENT_GRID } from '../../../constants/strates';

export function getContentGridNextPageUrl<T extends { paging?: { URLPage?: string } }>(
  data: T,
  dataPerPage: number = PAGINATION_CONTENT_GRID
): string | undefined {
  if (!data.paging?.URLPage) {
    return;
  }
  return addQueryParam(data.paging.URLPage, 'get', String(dataPerPage));
}
