import classNames from 'classnames';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import I18n from '../../../lang';
import stylesTemplate from './ContentGridTemplate.css';

type ContentGridErrorProps = {
  isFromDetail?: boolean;
  message?: string;
};

function ContentGridError({ isFromDetail = false, message }: ContentGridErrorProps): JSX.Element {
  const { t } = I18n.useTranslation();

  return (
    <section
      className={classNames(stylesTemplate.contentGridTemplate, {
        [stylesTemplate['contentGridTemplate--detailV5']]: isFromDetail,
      })}
    >
      <div className={stylesTemplate.contentGrid__error}>
        <ErrorMessage>{message ?? t('ErrorMessages.noContent')}</ErrorMessage>
      </div>
    </section>
  );
}

export default ContentGridError;
