import { Ratio, isSomeEnum } from '@canalplus/mycanal-commons';
import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { Placeholder } from '@canalplus/mycanal-sharedcomponent';
import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import { ApiV2CurrentPage } from '@dce-front/hodor-types/api/v2/content_grid/definitions';
import { ApiV2Contents } from '@dce-front/hodor-types/api/v2/strate_content/definitions';
import { CursorPagination, IndexPagination } from '@dce-front/hodor-types/modules/pagination/definitions';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TriggerVerticalScroll from '../../../components/TriggerVerticalScroll/TriggerVerticalScroll';
import { DEFAULT_PLACEHOLDER_NUMBER } from '../../../constants/common';
import useInfiniteQueryTemplate from '../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { useIntersectionObserver } from '../../../helpers/hooks/useIntersectionObserver';
import { MIDDLEWARE_CONTENTGRID_PAGE } from '../../../helpers/oneNavigation/middleware';
import { featIdentityV5Selector } from '../../../selectors/application/application-selectors';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isImmersiveSelector } from '../../../store/slices/immersive-selectors';
import { ContentStrateV5, DisplayParameters } from '../../../templates/LandingV5/data/formatter';
import { getFormattedContentGridUrl } from '../data/formater';
import { getContentGridNextPageUrl } from '../data/getContentGridNextPageUrl';
import { ContentGridState } from '../data/types';
import ContentGridError from './ContentGridError';
import ContentGridTemplate from './ContentGridTemplate';

export type ContentGridContainerProps = {
  contents?: ApiV2Contents[] | ContentStrateV5[];
  currentPage?: ApiV2CurrentPage;
  disableMetaUpdate?: boolean;
  displayParameters?: DisplayParameters;
  header?: ContentRowHeader;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  paging?: CursorPagination | IndexPagination;
  url?: string;
  isFromDetail?: boolean;
} & FromProp;

function ContentGridContainer({
  contents,
  currentPage,
  disableMetaUpdate = false,
  displayParameters: {
    imageRatio = Ratio.Ratio169,
    imageSize = 'normal',
    titleDisplayMode = TitleDisplayMode.All,
  } = {},
  from,
  header,
  onClickParameters,
  onFocusable,
  paging,
  isFromDetail = false,
  url,
}: ContentGridContainerProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const isImmersive = useSelector(isImmersiveSelector);
  const featIdentityV5 = useSelector(featIdentityV5Selector);
  const isFromRoot = from === 'root';

  const reactQueryOptions = useMemo(
    () => ({
      // if we have contents, do not trigger react-query and use initial data by default
      initialData: contents?.length
        ? {
            pages: [
              {
                contents,
                paging,
                currentPage,
              },
            ],
            pageParams: [],
          }
        : undefined,

      enabled: !contents?.length && !!url,
      getNextPageParam: (lastPageData) => getContentGridNextPageUrl(lastPageData),
      // @TODO tv device : cache is set to 0 here to avoid focus lost in the following scenario :
      // - open content grid via more info
      // - open an immersive
      // - open another content grid via more info
      // - back 2 times (to get the first content grid)
      // - focus lost
      // This is due to the fact that when going back, the focus is reset to page layer before
      // this component updates its DOM from react-query cache. Forcing a query refetch will
      // unregister / register the binder and make the back scenario work. This is not ideal and
      // should be removed once we find a proper way update the binder when a focused element is
      // removed from the DOM

      ...(isTvDevice ? { cgTime: 0, staleTime: 0 } : {}),
    }),
    [contents, currentPage, isTvDevice, paging, url]
  ) satisfies Partial<UseInfiniteQueryOptions<ContentGridState>>;

  const [{ isLoading, isError, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage, data }] =
    useInfiniteQueryTemplate<ContentGridState>(
      getFormattedContentGridUrl({ url, from }),
      {
        disableMetaUpdate,
        enableAlternateLinksUpdate: true,
        from,
        onClickParameters,
        template: FetchRequestTypes.ContentGrid,
      },
      reactQueryOptions,
      { featIdentityV5 }
    );

  /**
   * call onFocusable when receives new data and immersive display state changes
   */
  useEffect(() => {
    if (onFocusable && isSuccess) {
      onFocusable();
    }
  }, [onFocusable, isSuccess, isImmersive]);

  const { refCallback } = useIntersectionObserver({
    isEnabled: hasNextPage && !isFetchingNextPage,
    onIntersect: fetchNextPage,
  });

  const {
    imageSize: pageImageSize,
    imageRatio: pageImageRatio,
    titleDisplayMode: pageTitleDisplayMode,
  } = data?.pages?.[0]?.displayParameters || {};

  const contentGridImageSize = pageImageSize || imageSize;
  const contentGridImageRatio = pageImageRatio || isSomeEnum(Ratio)(pageImageRatio) ? pageImageRatio : imageRatio;
  const contentGridTitleDisplayMode =
    pageTitleDisplayMode && isSomeEnum(TitleDisplayMode)(pageTitleDisplayMode)
      ? (pageTitleDisplayMode as TitleDisplayMode)
      : titleDisplayMode;

  const hasContents = !!data?.pages?.[0]?.contents?.length;
  if (isError || (!isLoading && !hasContents)) {
    return <ContentGridError isFromDetail={isFromDetail} />;
  }

  return (
    <section>
      {isLoading ? (
        <Placeholder
          imageSize={contentGridImageSize}
          isFromDetail={isFromDetail}
          nbPlaceholder={DEFAULT_PLACEHOLDER_NUMBER}
          ratio={contentGridImageRatio}
        />
      ) : (
        <ContentGridTemplate
          data={data}
          header={header}
          imageRatio={contentGridImageRatio}
          imageSize={contentGridImageSize}
          titleDisplayMode={contentGridTitleDisplayMode}
          isFetchingNextPage={isFetchingNextPage}
          isFromDetail={isFromDetail}
          shouldRenderWithComponentMedia
          {...(isFromRoot && { middleware: MIDDLEWARE_CONTENTGRID_PAGE })}
        />
      )}
      <TriggerVerticalScroll ref={refCallback} />
    </section>
  );
}

export default ContentGridContainer;
